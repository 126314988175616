import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar';
import PageTitle from '../../components/pagetitle';
import About2 from '../../components/about2';
import TeamSection from '../../components/team';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';

const CasePage = () => {
	return (
		<Fragment>
			<Navbar />
			<PageTitle pageTitle={'About Us'} pagesub={'About'} />
			<About2 />
			<TeamSection />
			<Footer />
			<Scrollbar />
		</Fragment>
	);
};
export default CasePage;
