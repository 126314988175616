import React, { Component } from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import hero2 from '../../images/slider/slide-2.jpg';
import hero3 from '../../images/slider/slide-1.jpg';
import hero5 from '../../images/slider/slide-5.png';
import hero4 from '../../images/shape/shape.png';

import './style.css';

class Hero extends Component {
	render() {
		var settings = {
			dots: true,
			arrows: true,
			speed: 1200,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 2500,
			fade: true,
		};

		return (
			<section className='hero-slider hero-style-1'>
				<Slider {...settings}>
					<div className='slide'>
						<div
							className='slide-inner'
							style={{ backgroundImage: `url(${hero5})`, objectFit: 'contain' }}
						>
							<div className='container'>
								{/* <div className="slide-thumb">
                                    <span>GO FOR HELP</span>
                                </div> */}
								{/* <div className='slide-title'>
									<h2>JOIN US IN WORSHIP</h2>
								</div> */}
								{/* <div data-swiper-parallax='400' className='slide-text'>
									<p>
										And they overcame him by the blood of the Lamb, and by the
										word of their testimony; and they loved not their lives unto
										the death - Rev:12-11
									</p>
								</div> */}
							</div>
							<div className='slide-shape'>
								<img src={hero4} alt='' />
							</div>
						</div>
					</div>
					<div className='slide'>
						<div
							className='slide-inner'
							style={{ backgroundImage: `url(${hero2})`, objectFit: 'contain' }}
						>
							<div className='container'>
								<div className='slide-thumb'>
									<span>WELCOME</span>
								</div>
								<div className='slide-title'>
									<h2>We are always happy to see you.</h2>
								</div>
								<div data-swiper-parallax='400' className='slide-text'>
									<p></p>
								</div>
								<div className='slide-btns'>
									<Link to='/about' className='theme-btn'>
										Know more about us
										<i
											className='fa fa-angle-double-right'
											aria-hidden='true'
										></i>
									</Link>
								</div>
							</div>
							<div className='slide-shape'>
								<img src={hero4} alt='' />
							</div>
						</div>
					</div>
					<div className='slide'>
						<div
							className='slide-inner'
							style={{ backgroundImage: `url(${hero3})` }}
						>
							<div className='container'></div>
							<div className='slide-shape'>
								<img src={hero4} alt='' />
							</div>
						</div>
					</div>
				</Slider>
			</section>
		);
	}
}

export default Hero;
