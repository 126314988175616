import React from 'react';
import abimg4 from '../../images/shape/shape2.png';
import conventionImage from '../../images/convention/convention23.jpeg';

const About2 = (props) => {
	return (
		<div className='about-area convention'>
			<h2 className='convention-header'> 2023 Annual Beleivers Convention</h2>
			<div className='convention-image'>
				<img src={conventionImage} alt='' width='1100px' />
			</div>
			<div className='container'>
				<div className='row'>
					<div className='col-lg-5 col-md-12  grid col-12'>
						<div className='video-area'>
							{/* <img src={abimg} alt='' /> */}
							{/* <h2>Convnention 2023</h2> */}
							<div className='entry-media video-holder video-btn2'>
								{/* <VideoModal/> */}
							</div>
						</div>
					</div>
					<div className='col-lg-7 grid  col-md-12 col-12'>
						<div className='about-text'>
							<div className='section-title'></div>
							<p></p>
						</div>
					</div>
				</div>
			</div>
			<div className='ab-shape'>
				<img src={abimg4} alt='' />
			</div>
		</div>
	);
};

export default About2;
