import React from 'react';
import { Link } from 'react-router-dom';
import ins1 from '../../images/recent-posts/img-1.jpg';

import './style.css';

const BlogSidebar = () => {
	const SubmitHandler = (e) => {
		e.preventDefault();
	};

	return (
		<div className='col col-lg-4 col-12'>
			<div className='tp-blog-sidebar'>
				<div className='widget search-widget'>
					<h3>Search Here</h3>
					<form onSubmit={SubmitHandler}>
						<div>
							<input
								type='text'
								className='form-control'
								placeholder='Search Post..'
							/>
							<button type='submit'>
								<i className='ti-search'></i>
							</button>
						</div>
					</form>
				</div>
				<div className='widget category-widget'>
					<h3>Categories</h3>
					<ul>
						<li>
							<Link to='/blog'>Helping Poor</Link>
						</li>
						<li>
							<Link to='/blog'>ADRA</Link>
						</li>
						<li>
							<Link to='/blog'>Charity</Link>
						</li>
						<li>
							<Link to='/blog'>Free Food</Link>
						</li>
					</ul>
				</div>
				<div className='widget recent-post-widget'>
					<h3>Recent posts</h3>
					<div className='posts'>
						<div className='post'>
							<div className='img-holder'>
								<img src={ins1} alt='' />
							</div>
							<div className='details'>
								<h4>
									<Link to='/blog'>
										Many Children are suffering a lot for food.
									</Link>
								</h4>
								<span className='date'>22 Sep 2021</span>
							</div>
						</div>
					</div>
				</div>
				<div className='widget tag-widget'>
					<h3>Tags</h3>
					<ul>
						<li>
							<Link to='/blog'>Donations</Link>
						</li>
						<li>
							<Link to='/blog'>Charity</Link>
						</li>
						<li>
							<Link to='/blog'>Help</Link>
						</li>
						<li>
							<Link to='/blog'>Non Profit</Link>
						</li>
						<li>
							<Link to='/blog'>Poor People</Link>
						</li>
						<li>
							<Link to='/blog'>Helping Hand</Link>
						</li>
						<li>
							<Link to='/blog'>Video</Link>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default BlogSidebar;
