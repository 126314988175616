import React from 'react'
import {Link}  from 'react-router-dom'
import Logo from '../../images/logo/logo.jpeg'
import n1 from '../../images/instragram/insta1.1.png'
import n2 from '../../images/instragram/insta1.png'
import n3 from '../../images/instragram/insta2.png'
import n4 from '../../images/instragram/insta3.png'
import n5 from '../../images/instragram/insta4.png'
import n6 from '../../images/instragram/insta5.png'
import './style.css'

const Footer = (props) =>{

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

  return(
    <footer className="tp-site-footer">
        <div className="tp-upper-footer">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                        <div className="widget about-widget">
                            <div className="footer-logo widget-title">
                                <Link onClick={ClickHandler} to="index.html"><img width={65} src={Logo} alt="logo"/>Blessed<span>Hope</span></Link>
                            </div>
                            <ul>
                                <li><a target="_blank" href="https://twitter.com/blessedhopesda?lang=en" rel="noreferrer"><i className="ti-twitter-alt"></i></a></li>
                                <li><a target="_blank" href="https://www.facebook.com/blessedhope.sdac" rel="noreferrer"><i className="ti-facebook"></i></a></li>
                                <li><a target="_blank" href="https://www.instagram.com/blessedhope.sda/" rel="noreferrer"><i className="ti-instagram"></i></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col col-lg-2 col-md-6 col-sm-12 col-12">
                        <div className="widget link-widget">
                            <div className="widget-title">
                                <h3>Useful Links</h3>
                            </div>
                            <ul>
                                <li><Link onClick={ClickHandler} to="about/">About Us</Link></li>
                                <li><Link onClick={ClickHandler} to="contact/">Contact Us</Link></li>
                                <li><Link onClick={ClickHandler} to="event/">Our Events</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col col-lg-3 col-lg-offset-1 col-md-6 col-sm-12 col-12">
                        <div className="widget market-widget tp-service-link-widget">
                            <div className="widget-title">
                                <h3>Contact </h3>
                            </div>
                            <p>Come and worship with us </p>
                            <div className="contact-ft">
                                <ul>
                                    <li><i className="fi flaticon-pin"></i>Priory Hill, Dartford, DA1 2ES</li>
                                    <li><i className="fi flaticon-call"></i>01322838191</li>
                                    <li><i className="fi flaticon-envelope"></i>blessed@gmail.com</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                        <div className="widget instagram">
                            <div className="widget-title">
                                <h3>Instagram</h3>
                            </div>
                            <ul className="d-flex">
                                <li><a target="_blank" href="https://www.instagram.com/blessedhope.sda/" rel="noreferrer"><img src={n1} alt=""/></a></li>
                                <li><a target="_blank" href="https://www.instagram.com/blessedhope.sda/" rel="noreferrer"><img src={n2} alt=""/></a></li>
                                <li><a target="_blank" href="https://www.instagram.com/blessedhope.sda/" rel="noreferrer"><img src={n3} alt=""/></a></li>
                                <li><a target="_blank" href="https://www.instagram.com/blessedhope.sda/" rel="noreferrer"><img src={n4} alt=""/></a></li>
                                <li><a target="_blank" href="https://www.instagram.com/blessedhope.sda/" rel="noreferrer"><img src={n5} alt=""/></a></li>
                                <li><a target="_blank" href="https://www.instagram.com/blessedhope.sda/" rel="noreferrer"><img src={n6} alt=""/></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </footer>
  )
} 

export default Footer;