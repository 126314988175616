import React from 'react';
import Logo from '../../images/logo/logo.jpeg';
import { Link } from 'react-router-dom';
import MobileMenu from '../../components/MobileMenu';
import './style.css';

const Header = () => {
	return (
		<div className='middle-header'>
			<div className='header-style-3'>
				<div className='container'>
					<div className='header-content'>
						<div className='row'>
							<div className='col-lg-3 col-md-4 col-sm-4 col-4'>
								<div className='logo'>
									<Link to='/home' title='' style={{ textDecoration: 'none' }}>
										<img width={65} src={Logo} alt='' /> Blessed Hope
									</Link>
								</div>
							</div>
							<div className='col-lg-8 d-lg-block d-none'>
								<nav>
									<ul>
										<li>
											<Link className='active' to='/home' title=''>
												Home
											</Link>
										</li>
										<li>
											<Link to='/about' title=''>
												About
											</Link>
										</li>
										{/* <li><Link to="/case" title="">Causes</Link></li> */}
										<li>
											<Link to='/event' title=''>
												Events
											</Link>
											{/* <ul> */}
											{/* <li><Link to="/event" title="">Event</Link></li> */}
											{/* <li><Link to="/event-details" title="">Event Single</Link></li> */}
											{/* </ul> */}
										</li>
										{/* <li><Link to="/blog">Blog</Link></li> */}
										<li>
											<Link to='/contact' title=''>
												Contact
											</Link>
										</li>
										<li>
											<Link to='/convention' title=''>
												Convention
											</Link>
										</li>
									</ul>
								</nav>
							</div>
							<div className='col-md-2 col-sm-2 col-2'>
								<MobileMenu />
							</div>
						</div>

						<div className='clearfix'></div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Header;
