import React from 'react';
import './style.css';
import tm1 from '../../images/team/pastor-ayo.jpeg';
import tm2 from '../../images/team/elder-kenny-2.jpeg';
import tm3 from '../../images/team/elder-boris.jpeg';
import tm4 from '../../images/team/elder-seyi.jpeg';
import tm5 from '../../images/team/elder-wale.jpeg';
import tm6 from '../../images/team/elder-olatunde.jpeg';

const TeamSection = (props) => {
	return (
		<div className={`volunteer-area section-padding ${props.addclass}`}>
			<div className='container'>
				<div className='row'>
					<div className='col-lg-6 offset-lg-3'>
						<div className='section-title section-title2 text-center'>
							<div className='thumb-text'>
								<span>Team</span>
							</div>
							<h2>Pastoral Team</h2>
							<p>
								BHSDAC is blessed with committed Saints, with different skills
								and spiritual gifts, working together to enlarge the Kingdom of
								God.
							</p>
						</div>
					</div>
				</div>
				<div className='volunteer-wrap'>
					<div className='row'>
						<div className='col col-lg-3 col-md-6 custom-grid col-12'>
							<div className='volunteer-item'>
								<div className='volunteer-img'>
									<img src={tm1} alt='' style={{ maxHeight: '404px' }} />
								</div>
								<div className='volunteer-content'>
									{/* <h2><Link onClick={ClickHandler} to="/volunteer">Ayodeji Adesina (Lay Pastor)</Link></h2> */}
									<h2>Ayodeji Adesina (Lay Pastor)</h2>
									<span>
										Ayodeji Adesina has been the Pastor at BHSDAC since January
										2008. He is a visionary, administrator and motivational
										speaker, well known for his humorous and down to earth
										messages.
									</span>
								</div>
							</div>
						</div>
						<div className='col col-lg-3 col-md-6 custom-grid col-12'>
							<div className='volunteer-item'>
								<div className='volunteer-img'>
									<img src={tm2} alt='' style={{ maxHeight: '404px' }} />
								</div>
								<div className='volunteer-content'>
									{/* <h2><Link onClick={ClickHandler} to="/volunteer">Ayodeji Adesina (Lay Pastor)</Link></h2> */}
									<h2>Kehinde Jegede (First Elder)</h2>
									<span>
										Elder Kehinde Jegede joined the Pastoral team as a serving
										Elder in 2015 after serving as the Head Deacon for a 4 year
										period. He currently serves in the Communications department
										and also as a Sabbath school discussion leader. He is
										currently the responsible Elder for the Communications and
										Interest Coordination departments of the church here at
										Blessed Hope. He currenlty works within the Techonogy
										project delivery space.
									</span>
								</div>
							</div>
						</div>
						<div className='col col-lg-3 col-md-6 custom-grid col-12'>
							<div className='volunteer-item'>
								<div className='volunteer-img'>
									<img src={tm3} alt='' style={{ maxHeight: '404px' }} />
								</div>
								<div className='volunteer-content'>
									{/* <h2><Link onClick={ClickHandler} to="/volunteer">Ayodeji Adesina (Lay Pastor)</Link></h2> */}
									<h2>Bolaji Aborisade (Elder)</h2>
									<span>
										Elder Bolaji Aborisade oversees the deaconry and also serves
										as one of the Bible Study Teachers. He has an educational
										background in religious studies and also a Masters degree in
										law. He currently works as a Probation Prosecutor with the
										National Probation Service. He is well known for his jovial
										and friendly disposition, usually involved with organising
										the social events for the Church.
									</span>
								</div>
							</div>
						</div>
						<div className='col col-lg-3 col-md-6 custom-grid col-12'>
							<div className='volunteer-item'>
								<div className='volunteer-img'>
									<img src={tm6} alt='' />
								</div>
								<div className='volunteer-content'>
									{/* <h2><Link onClick={ClickHandler} to="/volunteer">Ayodeji Adesina (Lay Pastor)</Link></h2> */}
									<h2>Elder Temitope Olatunde (Elder)</h2>
									<span>
										olah70@yahoo.com He currently oversees the deaconry and also
										served in the same department for more than a decade. He has
										an educational background in religious studies, a Bsc degree
										in Mental Health and a Post Graduate Diploma in Public
										Health and Health Promotion. He currently works with the NHS
										Organisation as a Senior Mental Health Pratitioner.
									</span>
								</div>
							</div>
						</div>
						<div className='col col-lg-3 col-md-6 custom-grid col-12'>
							<div className='volunteer-item'>
								<div className='volunteer-img'>
									<img src={tm4} alt='' />
								</div>
								<div className='volunteer-content'>
									{/* <h2><Link onClick={ClickHandler} to="/volunteer">Ayodeji Adesina (Lay Pastor)</Link></h2> */}
									<h2>Seyi Dare (Elder)</h2>
									<span>
										Elder Seyi Dare is a member of the prayer team. He currently
										serves as the Men’s Ministry Leader and as one of the
										Sabbath School Study Teachers. He has an educational
										background in Computer Science and also a Bsc degree in
										Mental Health Practice. He currently works as a Forensic
										Mental Health Practitioner.
									</span>
								</div>
							</div>
						</div>
						<div className='col col-lg-3 col-md-6 custom-grid col-12'>
							<div className='volunteer-item'>
								<div className='volunteer-img'>
									<img src={tm5} alt='' style={{ maxHeight: '404px' }} />
								</div>
								<div className='volunteer-content'>
									{/* <h2><Link onClick={ClickHandler} to="/volunteer">Ayodeji Adesina (Lay Pastor)</Link></h2> */}
									<h2>Wale Oladipo (Elder)</h2>
									<span>
										Dr ‘Wale Oladipo currently works as a Physician in General
										Medicine at the Luton and Dunstable NHS Foundation Trust. He
										is a medical doctor by training and also holds a Master
										degree in Public Health in the United Kingdom. He is a
										member of the Praise Team, one of the Sabbath School study
										Teachers at Blessed Hope SDA Church. Above all things He is
										a man of Faith who declares the word as though they were.
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TeamSection;
