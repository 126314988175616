import React from 'react';
import event1 from '../../images/event/aunty-tola-praying.jpeg';
import event4 from '../../images/event/1.png';
import event5 from '../../images/event/2.png';

import './style.css';

const EventSection = (props) => {
	const getNextSaturday = (date = new Date()) => {
		const currentDate = new Date(date.getTime());

		const nextSaturday = new Date(
			currentDate.setDate(
				currentDate.getDate() + ((7 - currentDate.getDay() + 5) % 6 || 7)
			)
		);

		return nextSaturday;
	};

	return (
		<div className='event-area section-padding'>
			<div className='container'>
				<div className='row'>
					<div className='col-lg-6 offset-lg-3'>
						<div className='section-title section-title2 text-center'>
							<div className='thumb-text'>
								<span>EVENTS</span>
							</div>
							<h2>Our Upcoming Events</h2>
							<p></p>
						</div>
					</div>
				</div>
				<div className='row'>
					<div className='col-12'>
						<div className='event-item'>
							<div className='event-img'>
								<img src={event1} alt='' height={150} />
							</div>
							<div className='event-text'>
								<div className='event-left'>
									<div className='event-l-text'>
										<span>
											{getNextSaturday().toLocaleString('en-GB', {
												month: 'long',
												timeZone: 'GMT',
											})}
										</span>
										<h4>{getNextSaturday().getDate()}</h4>
									</div>
								</div>
								<div className='event-right'>
									<ul>
										<li>
											<i className='ti-location-pin'></i> 10:00 AM - 1:00 PM
										</li>
										<li>
											<i className='ti-location-pin'></i> Priory Hill
										</li>
									</ul>
									<h2>Sabbath service.</h2>
									<p>Join us for out sabbath service</p>
								</div>
							</div>
						</div>
						{/* Convention Item */}

						{/* All new event goes into it */}
					</div>
				</div>
			</div>
			<div className='shape1'>
				<img src={event4} alt='' />
			</div>
			<div className='shape2'>
				<img src={event5} alt='' />
			</div>
		</div>
	);
};

export default EventSection;
