import React from 'react';
import { Link } from 'react-router-dom';
import BlogSidebar from '../BlogSidebar';
import './style.css';
import blog1 from '../../images/blog/img-5.jpg';
import blog4 from '../../images/blog/admin.jpg';

const BlogList = () => {
	return (
		<section className='tp-blog-pg-section section-padding'>
			<div className='container'>
				<div className='row'>
					<div className='col col-lg-8 col-12'>
						<div className='tp-tp-blog-content'>
							<div className='post format-standard-image'>
								<div className='entry-media'>
									<img src={blog1} alt='' />
								</div>
								<ul className='entry-meta'>
									<li>
										<Link to='/blog'>
											<img src={blog4} alt='' /> By Admin
										</Link>
									</li>
									<li>
										<Link to='/blog'>
											<i className='ti-calendar'></i> Sep 25,2021
										</Link>
									</li>
									<li>
										<Link to='/blog'>
											<i className='ti-heart'></i> 35
										</Link>
									</li>
								</ul>
								<h3>
									<Link to='/blog'>Blog header example</Link>
								</h3>
								<p>All the blog details go here </p>
								<Link to='/blog-details' className='read-more'>
									Read More...
								</Link>
							</div>
							<div className='pagination-wrapper pagination-wrapper-left'>
								<ul className='pg-pagination'>
									<li>
										<Link to='/blog-details' aria-label='Previous'>
											<i className='fi ti-angle-left'></i>
										</Link>
									</li>
									<li className='active'>
										<Link to='/blog'>1</Link>
									</li>
									<li>
										<Link to='/blog'>2</Link>
									</li>
									<li>
										<Link to='/blog'>3</Link>
									</li>
									<li>
										<Link to='/blog'>4</Link>
									</li>
									<li>
										<Link to='/blog' aria-label='Next'>
											<i className='fi ti-angle-right'></i>
										</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<BlogSidebar />
				</div>
			</div>
		</section>
	);
};

export default BlogList;
