import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import Hero from '../../components/hero'
import About from '../../components/about'
import CtaSection from '../../components/cta'
import EventSection from '../../components/event'
import TeamSection from '../../components/team'
import Footer from '../../components/footer'

import Scrollbar from '../../components/scrollbar'


const HomePage =() => {
    return(
        <Fragment>
            <Navbar/>
            <Hero/>
            <About/>
            {/* <Causes/> */}
            <CtaSection/>
            <EventSection/>
            <TeamSection/>
            {/* <Testimonial/> */}
            {/* <BlogSection/> */}
            {/* <Partner/> */}
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default HomePage;