import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar';
import PageTitle from '../../components/pagetitle';
import Convention from '../../components/convention';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
const CasePage = () => {
	return (
		<Fragment>
			<Navbar />
			{/* <PageTitle pageTitle={'Convention'} pagesub={'Convention'} /> */}
			<Convention />
			<Footer />
			<Scrollbar />
		</Fragment>
	);
};
export default CasePage;
