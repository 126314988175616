import React from 'react'
import './style.css'

const CtaSection = (props) => {

    return(
        <div className="cta-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-md-12 col-12">
                        <div className="cta-left">
                            <h2>Blessed Hope SDA Church Info</h2>
                        </div>
                    </div>
                    <div className="col-lg-6 offset-lg-1 col-md-12 col-12">
                        <div className="cta-wrap">
                            <div className="cta-call">
                                <span>Call Us!</span>
                                <h3>0 1 3 2 2 8 3 8 1 9 1</h3>
                            </div>
                            <div className="cta-call">
                                <span>E-mail Us!</span>
                                <h3>pastor@bhsdac.org.uk</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CtaSection;