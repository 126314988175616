import React from 'react';
import VideoModal from '../../components/ModalVideo';
import abimg from '../../images/about/3.jpeg';
import abimg4 from '../../images/shape/shape2.png';

const About2 = (props) => {
	return (
		<div className='about-area section-padding'>
			<div className='container'>
				<div className='row'>
					<div className='col-lg-5 col-md-12  grid col-12'>
						<div className='video-area'>
							<img src={abimg} alt='' />
							<div className='entry-media video-holder video-btn2'>
								<VideoModal />
							</div>
						</div>
					</div>
					<div className='col-lg-7 grid  col-md-12 col-12'>
						<div className='about-text'>
							<div className='section-title'>
								<div className='thumb-text'>
									<span>ABOUT US</span>
								</div>
								<h2>
									Welcome to Blessed Hope SDA Church . Join us for worship every{' '}
									<span>Saturday</span> @ our Worship Centre{' '}
									<span>Priory Hill, Dartford, DA1 2ES</span>{' '}
								</h2>
							</div>
							<p>
								At BHSDAC, we consider everyone part of God’s family, so from
								your very first visit, you are considered a member of the
								family. We hope that from being a visiting friend, you may
								progress to becoming a participating member.
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className='ab-shape'>
				<img src={abimg4} alt='' />
			</div>
		</div>
	);
};

export default About2;
