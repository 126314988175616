/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import ContactForm from '../ContactFrom';
import './style.css';

const Contactpage = () => {
	return (
		<section className='contact-pg-contact-section section-padding'>
			<div className='container'>
				<div className='row'>
					<div className='col col-lg-6 col-12'>
						<div className='section-title-s3 section-title-s5'>
							<h2>Contact us</h2>
						</div>
						<div className='contact-details'>
							<p> Text about contacting us should go here </p>
							<ul>
								<li>
									<div className='icon'>
										<i className='ti-location-pin'></i>
									</div>
									<h5>Our Location</h5>
									<p>1 Priory Hill, Dartford, DA1 2ES </p>
								</li>
								<li>
									<div className='icon'>
										<i className='ti-mobile'></i>
									</div>
									<h5>Phone</h5>
									<p>01322838191</p>
								</li>
								<li>
									<div className='icon'>
										<i className='ti-email'></i>
									</div>
									<h5>Email</h5>
									<p>blessed@gmail.com</p>
								</li>
							</ul>
						</div>
					</div>
					<div className='col col-lg-6 col-12'>
						<div className='contact-form-area'>
							<div className='section-title-s3 section-title-s5'>
								<h2>Quick Contact Form</h2>
							</div>
							<div className='contact-form'>
								<ContactForm />
							</div>
						</div>
					</div>
				</div>
				<div className='row'>
					<div className='col col-xs-12'>
						<h4>Still working on the google maps specifics</h4>
						<div className='contact-map'>
							<iframe src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2486.596339748085!2d0.20962151609415858!3d51.447206679625175!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8b1eb52521bdb%3A0xf7197dcb37a948e0!2sPriory%20Hill%2C%20Dartford!5e0!3m2!1sen!2suk!4v1632589463080!5m2!1sen!2suk'></iframe>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Contactpage;
